<template>
  <section>
    <bg-grid>
      <bg-grid-item :col="6">
        <bg-search-bar
          v-model="localValues.search"
          with-select-input
          placeholder="Cari di sini"
          :select-value="localValues.searchSelect"
          :select-options="filterOptions.searchbar"
          @select-change="selectChanged"
          disabled
        />
      </bg-grid-item>
      <bg-grid-item :col="6">
        <div class="flex">
          <bg-button size="lg" @click="openFilterModal" class="filter-button">
            <bg-badge-counter
              v-if="totalModalFilter"
              :value="totalModalFilter"
              variant="black"
            />
            <bg-icon v-else name="filter" size="sm" class="va-m" />
            <span class="ml-8">Filter</span>
          </bg-button>
          <bg-button size="lg" class="ml-16" @click="resetFilter(true)"
            >Reset</bg-button
          >
          <bg-button
            size="lg"
            variant="primary"
            class="ml-16"
            @click="submitSearch"
          >
            Cari
          </bg-button>
          <bg-button
            size="lg"
            variant="secondary"
            class="right-align-button"
            @click="goToAddPage"
            v-if="accessOwnerExpenditureActions"
          >
            Tambah Data
          </bg-button>
        </div>
      </bg-grid-item>
    </bg-grid>

    <bg-modal
      v-model="showFilterModal"
      desktop-size="lg"
      data-testid="filter-modal"
    >
      <div class="mb-32">
        <bg-text tag="h4" size="title-2">Filter</bg-text>
        <bg-text size="body-2">Silakan pilih sesuai kebutuhan Anda</bg-text>
      </div>
      <bg-grid>
        <bg-grid-item :col="12" class="mb-8">
          <bg-text size="title-3">Status Konfirmasi</bg-text>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-field class="mb-0" label="Status Konfirmasi Manager">
            <search-checkbox
              name="managerConfirmationStatus"
              placeholder="Pilih status konfirmasi"
              :list="filterOptions.confirmationStatus"
              :checked="localValues.modal.manager"
              :max-checked="0"
              disable-search
              @emit-checked-array="localValues.modal.manager = $event"
            />
          </bg-field>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-field class="mb-0" label="Status Konfirmasi Finance">
            <search-checkbox
              name="financeConfirmationStatus"
              placeholder="Pilih status konfirmasi"
              :list="filterOptions.confirmationStatus"
              :checked="localValues.modal.finance"
              :max-checked="0"
              disable-search
              @emit-checked-array="localValues.modal.finance = $event"
            />
          </bg-field>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-field class="mb-0" label="Kategori Biaya">
            <search-checkbox
              name="expenseCategory"
              placeholder="Pilih kategori biaya"
              :list="filterOptions.expenseCategory"
              :is-loading-list="filterLoading"
              :checked="localValues.modal.expenseCategory"
              :max-checked="0"
              disable-search
              @emit-checked-array="localValues.modal.expenseCategory = $event"
              @dropdown-open="onDropdownOpen"
            />
          </bg-field>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-field class="mb-0" label="Tujuan Transfer Pengeluaran">
            <search-checkbox
              name="transferDestination"
              placeholder="Pilih tujuan transfer pengeluaran"
              :list="filterOptions.transferDestination"
              :is-loading-list="filterLoading"
              :checked="localValues.modal.transferDestination"
              :max-checked="0"
              @emit-checked-array="
                localValues.modal.transferDestination = $event
              "
              @dropdown-open="onDropdownOpen"
            />
          </bg-field>
        </bg-grid-item>
        <bg-grid-item :col="12" class="mb-8">
          <bg-text size="title-3">Tanggal Dibuat</bg-text>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-field class="mb-0" description="Tanggal Mulai">
            <bg-datepicker
              v-model="localValues.modal.startDate"
              placeholder="Pilih tanggal di sini"
              fixed-position="top-left"
              :disabled-dates="{ from: filterValues.modal.endDate }"
            />
          </bg-field>
        </bg-grid-item>
        <bg-grid-item :col="6">
          <bg-field class="mb-0" description="Tanggal akhir">
            <bg-datepicker
              v-model="localValues.modal.endDate"
              placeholder="Pilih tanggal di sini"
              fixed-position="top-left"
              :disabled-dates="{ to: filterValues.modal.startDate }"
            />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <template #footer>
        <div class="flex justify-end">
          <bg-button
            class="mr-16"
            variant="tertiary"
            size="lg"
            @click="resetFilter(false)"
            >Reset</bg-button
          >
          <bg-button
            variant="primary"
            size="lg"
            :disabled="totalModalFilter === 0"
            @click="submitSearch"
            >Terapkan</bg-button
          >
        </div>
      </template>
    </bg-modal>
  </section>
</template>

<script>
import {
  BgSearchBar,
  BgGrid,
  BgGridItem,
  BgButton,
  BgModal,
  BgBadgeCounter,
  BgIcon,
  BgDatepicker,
  BgField,
  BgText,
} from 'bangul-vue';

export default {
  name: 'OtherTransactionFilters',

  components: {
    BgSearchBar,
    BgGrid,
    BgGridItem,
    BgButton,
    BgModal,
    BgBadgeCounter,
    BgIcon,
    BgDatepicker,
    BgField,
    BgText,
    SearchCheckbox: () => import('@admin_molecules/SearchCheckbox'),
  },

  props: {
    filterValues: {
      type: Object,
      required: true,
    },
    filterLoading: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showFilterModal: false,
      localValues: {},
    };
  },

  computed: {
    accessOwnerExpenditureActions() {
      return this.$store.getters.xCheckUserPermission('add-owner-expenditure');
    },
    clonedValues() {
      return JSON.parse(JSON.stringify(this.localValues));
    },
    totalModalFilter() {
      let total = 0;
      const values = Object.values(this.filterValues.modal);
      values.forEach(value => {
        if (value !== null && value.length !== 0) total += 1;
      });

      return total;
    },
  },

  watch: {
    filterValues: {
      handler() {
        this.localValues = { ...this.filterValues };
      },
      immediate: true,
      deep: true,
    },
    clonedValues: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.onFilterChange();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    goToAddPage() {
      this.$emit('go-to-add-page');
    },
    resetFilter(all) {
      this.showFilterModal = false;
      this.$emit('reset-filter', all);
    },
    submitSearch() {
      this.showFilterModal = false;
      this.$emit('submit-search');
    },
    onDropdownOpen() {
      this.$emit('on-dropdown-open');
    },
    openFilterModal() {
      this.showFilterModal = true;
    },
    onFilterChange() {
      this.$emit('on-filter-change', { ...this.localValues });
    },
    selectChanged(value) {
      this.localValues.searchSelect = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-align-button {
  margin-left: auto;
}
</style>
